import React from 'react';
import FlexibleCarousel from "@/app/components/carousels/FlexibleCarousel";
import TireServiceCard from "@/app/components/cards/TireServiceCard";
import ElectricalServiceCard from "@/app/components/cards/ElectricalServiceCard";
import EngServiceCard from "@/app/components/cards/EngServiceCard";
import TransServiceCard from "@/app/components/cards/TransServiceCard";
import AirConServiceCard from "@/app/components/cards/AirConServiceCard";
import OilChangeServiceCard from "@/app/components/cards/OilChangeServiceCard";
import SmogServiceCard from "@/app/components/cards/SmogServiceCard";
import MaintenanceServiceCard from "@/app/components/cards/MaintenenceServiceCard";
import BrakeServiceCard from "@/app/components/cards/BrakeServiceCard";
import ExhServiceCard from "@/app/components/cards/ExhServiceCard";
import SusServiceCard from "@/app/components/cards/SusServiceCard";
import CoolingSystemServiceCard from "@/app/components/cards/CoolSysServiceCard";


const PopulatedServiceCarousel: React.FC = () => {
    return (
        <FlexibleCarousel>
            <TireServiceCard/>
            <ElectricalServiceCard/>
            <EngServiceCard/>
            <TransServiceCard/>
            <AirConServiceCard/>
            <OilChangeServiceCard></OilChangeServiceCard>
            <SmogServiceCard></SmogServiceCard>
            <MaintenanceServiceCard></MaintenanceServiceCard>
            <BrakeServiceCard></BrakeServiceCard>
            <ExhServiceCard></ExhServiceCard>
            <SusServiceCard/>
            <CoolingSystemServiceCard/>

        </FlexibleCarousel>
    );
};

export default PopulatedServiceCarousel;
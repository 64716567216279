import React from 'react';
import ServiceCard from '../templates/ServiceCard';
import { FaFan } from 'react-icons/fa';

const CoolingSystemServiceCard: React.FC = () => {
    return (
        <ServiceCard
            title="Cooling System Service"
            description="Keep your engine at the right temperature with our expert cooling system services. We provide inspections, repairs, and maintenance to ensure your vehicle stays cool and runs efficiently."
            image="/imgs/porscheSmog.png"
            icon={<FaFan className="text-white text-xl" />}
            href="/services/cooling"
        />
    );
};

export default CoolingSystemServiceCard;

import React from 'react';
import ServiceCard from '../templates/ServiceCard';
import { FaOilCan } from 'react-icons/fa';

const EngServiceCard: React.FC = () => {
    return (
        <ServiceCard
            title="Engine Repair"
            description="Maximize your engine's performance and efficiency with our professional engine repair services. We provide thorough inspections and repairs to keep your vehicle running smoothly."
            image="/imgs/engine.jpg"
            icon={<FaOilCan className="text-white text-xl" />}
            href="/services/engine"
        />
    );
};

export default EngServiceCard;
import React from 'react';
import ServiceCard from '../templates/ServiceCard';
import { Cog } from "lucide-react"

const OilChangeServiceCard: React.FC = () => {
    return (
        <ServiceCard
            title="Oil Changes"
            description="Keep your engine running smoothly with our premium oil change services at Masonic Smog & Repair. Plus, enjoy a FREE multi-point inspection with every oil change!"
            image="/imgs/oilChangeShop.jpg"
            icon={<Cog className="text-white text-xl" />}
            href="/services/oilChange"
        />
    );
};

export default OilChangeServiceCard;
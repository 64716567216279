import React from 'react';
import ServiceCard from '../templates/ServiceCard';
import { FaOilCan } from 'react-icons/fa';

const SusServiceCard: React.FC = () => {
    return (
        <ServiceCard
            title="Suspension Services"
            description="Ensure a smooth ride with our expert suspension services. We offer detailed diagnostics and repairs to maintain your vehicle's handling and stability."
            image="/imgs/corvette.jpg"
            icon={<FaOilCan className="text-white text-xl" />}
            href="/services/suspension"
        />
    );
};

export default SusServiceCard;
import React from 'react';
import ServiceCard from '../templates/ServiceCard';
import { FaOilCan } from 'react-icons/fa';

const ElectricalServiceCard: React.FC = () => {
    return (
        <ServiceCard
            title="Auto Electric Services"
            description="Solve your vehicle's electrical issues with our auto electric services. We provide accurate diagnostics and repairs to keep your car's electrical system functioning properly."
            image="/imgs/bmwEngineBay.jpg"
            icon={<FaOilCan className="text-white text-xl" />}
            href="/services/electrical"
        />
    );
};

export default ElectricalServiceCard;
import React from 'react';
import ServiceCard from '../templates/ServiceCard';
import { FaOilCan } from 'react-icons/fa';

const ExhServiceCard: React.FC = () => {
    return (
        <ServiceCard
            title="Exhaust Repair"
            description="Ensure your vehicle's exhaust system is functioning properly with our professional exhaust repair services. We provide thorough inspections and repairs to keep your vehicle running smoothly."
            image="/imgs/mazdaExhaust.jpg"
            icon={<FaOilCan className="text-white text-xl" />}
            href="/services/exhaust"
        />
    );
};

export default ExhServiceCard;
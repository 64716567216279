"use client";

import { useState, useEffect, useRef } from "react";
import Image from "next/image";
import Link from "next/link";
import {
  Instagram,

  Clock,
  MapPin,
  Phone,
  ChevronDown,
} from "lucide-react";

import BookAppointmentButtonWhite from "@/app/components/buttons/BookAppointmentButtonWhite";
import PopulatedServiceCarousel from "@/app/components/carousels/PopulatedServiceCarousel";

export default function NavBarWithCarousel() {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isServicesOpen, setIsServicesOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);
    };

    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsServicesOpen(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      window.removeEventListener("scroll", handleScroll);
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className="bg-themeSecondary text-white">
      {/* Top bar - only visible on desktop when not scrolled */}
      <div
        className={`hidden md:flex justify-between items-center transition-all duration-300 ${isScrolled ? "h-0 opacity-0 overflow-hidden" : "h-8"}`}
      >
        <div className="container mx-auto px-6 flex justify-between items-center max-w-7xl">
          <div className="flex items-center">
            <Clock className="w-4 h-4 mr-2" />
            <span className="text-sm">Mon-Sat: 9AM-5PM</span>
          </div>
          <div className="flex items-center">
            <MapPin className="w-4 h-4 mr-2" />
            <a
              href="https://www.google.com/maps/dir//1698+Fell+St,+San+Francisco,+CA+94117/@37.773343,-122.5279969,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x808581a785e8e985:0x35e5f6ed73060a38!2m2!1d-122.4455959!2d37.7733717?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D"
              className="text-sm mr-4 hover:underline"
            >
              1698 Fell St, San Francisco, CA 94117
            </a>
            <Phone className="w-4 h-4 mr-2" />
            <a href="tel:1(415)-272-0531" className="text-sm hover:underline">
              (415) 272-0531
            </a>
          </div>
        </div>
      </div>

      {/* Main navbar */}
      <div
        className={`bg-themePrimary ${isScrolled ? "md:fixed md:top-0 md:left-0 md:right-0 md:z-50" : ""}`}
      >
        <div className="container mx-auto px-6 py-5 flex justify-between items-center max-w-7xl">
          <Link href="/">
            <Image
              src="/imgs/msr.png"
              alt="Masonic Logo"
              width={200}
              height={200}
              className="w-auto h-14"
            />
          </Link>

          {/* Desktop menu items - hidden on mobile */}
          <div className="hidden md:flex items-center space-x-8">
            <Link href="/" className="text-lg hover:text-gray-300">
              Home
            </Link>
            <Link href="/about" className="text-lg hover:text-gray-300">
              About Us
            </Link>
            <div className="relative" ref={dropdownRef}>
              <button
                className="flex items-center text-lg hover:text-gray-300"
                onClick={() => setIsServicesOpen(!isServicesOpen)}
              >
                Services <ChevronDown className="ml-1 w-3 h-3" />
              </button>
              {isServicesOpen && (
                <div className="absolute top-full left-1/2 transform -translate-x-1/2 w-screen max-w-4xl bg-white text-[#2A1B5D] rounded-md shadow-lg p-4 z-50">
                  <div className={"text-center text-3xl font-bold mb-3"}>Services Offered</div>
                  <PopulatedServiceCarousel />
                  <Link
                    href="/services"
                    className="block mt-4 text-sm font-semibold hover:underline"
                  >
                    View All Services
                  </Link>

                </div>
              )}
            </div>
            <Link href="/contact" className="text-lg hover:text-gray-300">
              Contact
            </Link>
          </div>

          {/* Right-aligned items - hidden on mobile */}
          <div className="hidden md:flex items-center space-x-6">
            <a
              href="https://www.instagram.com/masonicsmogandrepair/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Instagram className="w-6 h-6" />
            </a>
            {/*<a*/}
            {/*  href="https://facebook.com"*/}
            {/*  target="_blank"*/}
            {/*  rel="noopener noreferrer"*/}
            {/*>*/}
            {/*  <Facebook className="w-6 h-6" />*/}
            {/*</a>*/}
            <BookAppointmentButtonWhite />
          </div>
        </div>
      </div>
    </nav>
  );
}

import React from 'react';
import ServiceCard from '../templates/ServiceCard';
import { FaOilCan } from 'react-icons/fa';

const MaintenanceServiceCard: React.FC = () => {
    return (
        <ServiceCard
            title="General Maintenance"
            description="Our general and scheduled maintenance services cover all your vehicle's needs. We offer thorough inspections and preventative care to ensure your car's reliability and performance."
            image="/imgs/truckservice.jpg"
            icon={<FaOilCan className="text-white text-xl" />}
            href="/services/maintenance"
        />
    );
};

export default MaintenanceServiceCard;
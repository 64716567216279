import React from 'react';
import ServiceCard from '../templates/ServiceCard';
import { Cog } from "lucide-react"

const SmogServiceCard: React.FC = () => {
    return (
        <ServiceCard
            title="Smog Check"
            description="Stay compliant with emissions regulations with our efficient and accurate smog check services. Masonic Smog & Repair helps you pass your smog test with ease.Stay compliant with emissions regulations with our efficient and accurate smog check services. Masonic Smog & Repair helps you pass your smog test with ease."
            image="/imgs/redporsche.jpg"
            icon={<Cog className="text-white text-xl" />}
            href="/services/smogs"
        />
    );
};

export default SmogServiceCard;
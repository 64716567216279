"use client"

import { useState, useEffect, useRef } from 'react'
import { useMediaQuery } from 'react-responsive'
import { motion, AnimatePresence } from 'framer-motion'
import { Menu, X, ChevronLeft } from 'lucide-react'
import { MdEmail, MdPhone, MdLocationOn } from "react-icons/md"
import { FaInstagram } from "react-icons/fa"
import Link from "next/link"

export default function MobileNav() {
    const [isOpen, setIsOpen] = useState(false)
    const [currentMenu, setCurrentMenu] = useState<'main' | 'services'>('main')
    const isMobile = useMediaQuery({ maxWidth: 768 })
    const [isMounted, setIsMounted] = useState(false)
    const menuRef = useRef<HTMLDivElement>(null)

    useEffect(() => {
        setIsMounted(true)

        const handleClickOutside = (event: MouseEvent) => {
            if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
                setIsOpen(false)
                setCurrentMenu('main')
            }
        }

        if (isOpen) {
            document.addEventListener('mousedown', handleClickOutside)
        }

        return () => {
            document.removeEventListener('mousedown', handleClickOutside)
        }
    }, [isOpen])

    if (!isMounted) return null

    const mainMenu = (
        <ul className="space-y-4">
            <li>
                <a href="/" className="text-lg font-medium hover:text-primary">Home</a>
            </li>
            <li>
                <a href="/about" className="text-lg font-medium hover:text-primary">About Us</a>
            </li>
            <li>
                <button
                    onClick={() => setCurrentMenu('services')}
                    className="text-lg font-medium hover:text-primary"
                >
                    Services
                </button>
            </li>
            <li>
                <a href="/contact" className="text-lg font-medium hover:text-primary">Contact</a>
            </li>
        </ul>
    )

    const servicesMenu = (
        <>
            <button
                onClick={() => setCurrentMenu('main')}
                className="flex items-center text-lg font-medium hover:text-primary mb-4"
            >
                <ChevronLeft className="w-5 h-5 mr-2" />
                Back
            </button>
            <ul className="space-y-4">
                <li>
                    <a href="/services/" className="text-lg font-bold hover:text-primary">View All
                        Services</a>
                </li>
                <li>
                    <a href="/services/smogs" className="text-lg font-medium hover:text-primary">Smog Check</a>
                </li>
                <li>
                    <a href="/services/oilChange" className="text-lg font-medium hover:text-primary">Oil Change</a>
                </li>
                <li>
                    <a href="/services/airConditioning" className="text-lg font-medium hover:text-primary">Air
                        Conditioning</a>
                </li>
                <li>
                    <a href="/services/brakeRepair" className="text-lg font-medium hover:text-primary">Brake Service</a>
                </li>
                <li>
                    <a href="/services/electrical" className="text-lg font-medium hover:text-primary">Auto Electric
                        Services</a>
                </li>
                <li>
                    <a href="/services/cooling" className="text-lg font-medium hover:text-primary">Cooling System
                        Services</a>
                </li>
                <li>
                    <a href="/services/engine" className="text-lg font-medium hover:text-primary">Engine Repair</a>
                </li>
                <li>
                    <a href="/services/exhaust" className="text-lg font-medium hover:text-primary">Exhaust Repair</a>
                </li>
                <li>
                    <a href="/services/maintenance" className="text-lg font-medium hover:text-primary">General
                        Maintenance</a>
                </li>
                <li>
                    <a href="/services/suspension" className="text-lg font-medium hover:text-primary">Suspension
                        Services</a>
                </li>
                <li>
                    <a href="/services/tires" className="text-lg font-medium hover:text-primary">Tire Sales &
                        Service</a>
                </li>
                <li>
                    <a href="/services/trans" className="text-lg font-medium hover:text-primary">Transmission Services</a>
                </li>
            </ul>
        </>
    )

    return (
        <>
            {isMobile && (
                <>
                    <button
                        onClick={() => setIsOpen(true)}
                        className="fixed top-4 right-4 z-50 p-2 bg-primary text-primary-foreground rounded-full shadow-lg"
                        aria-label="Open menu"
                    >
                        <Menu className="w-6 h-6"/>
                    </button>

                    <AnimatePresence>
                        {isOpen && (
                            <motion.div
                                ref={menuRef}
                                initial={{x: '100%'}}
                                animate={{x: 0}}
                                exit={{x: '100%'}}
                                transition={{type: 'spring', damping: 20, stiffness: 100}}
                                className="fixed inset-y-0 right-0 w-64 bg-background shadow-lg z-50 overflow-y-auto"
                            >
                                <div className="p-4">
                                    <button
                                        onClick={() => {
                                            setIsOpen(false)
                                            setCurrentMenu('main')
                                        }}
                                        className="absolute top-4 right-4 p-2 text-foreground"
                                        aria-label="Close menu"
                                    >
                                        <X className="w-6 h-6" />
                                    </button>
                                    <nav className="mt-8">
                                        <AnimatePresence mode="wait">
                                            <motion.div
                                                key={currentMenu}
                                                initial={{ opacity: 0, y: 20 }}
                                                animate={{ opacity: 1, y: 0 }}
                                                exit={{ opacity: 0, y: -20 }}
                                                transition={{ duration: 0.2 }}
                                            >
                                                {currentMenu === 'main' ? mainMenu : servicesMenu}
                                            </motion.div>
                                        </AnimatePresence>
                                    </nav>
                                </div>
                            </motion.div>
                        )}
                    </AnimatePresence>

                    {isOpen && (
                        <div
                            className="fixed inset-0 bg-black bg-opacity-50 z-40"
                            onClick={() => {
                                setIsOpen(false)
                                setCurrentMenu('main')
                            }}
                        />
                    )}

                    <nav className="z-20 fixed bottom-0 left-0 right-0 bg-background border-t border-border md:hidden">
                        <div className="flex justify-around items-center h-16">
                            <Link href="https://www.instagram.com/masonicsmogandrepair/" target="_blank" rel="noopener noreferrer" className="flex flex-col items-center justify-center w-full h-full text-themeSecondary hover:text-primary">
                                <FaInstagram className="h-6 w-6" />
                            </Link>
                            <Link href="mailto:masonicautorepair@autodemand.org" className="flex flex-col items-center justify-center w-full h-full text-themeSecondary hover:text-primary">
                                <MdEmail className="h-6 w-6" />
                            </Link>
                            <Link href="https://www.google.com/maps/dir//1698+Fell+St,+San+Francisco,+CA+94117/@37.773343,-122.5279969,12z/data=!4m8!4m7!1m0!1m5!1m1!1s0x808581a785e8e985:0x35e5f6ed73060a38!2m2!1d-122.4455959!2d37.7733717?entry=ttu&g_ep=EgoyMDI0MTAwMi4xIKXMDSoASAFQAw%3D%3D"
                                  target="_blank" rel="noopener noreferrer" className="flex flex-col items-center justify-center w-full h-full text-themeSecondary hover:text-primary">
                                <MdLocationOn className="h-6 w-6" />
                            </Link>
                            <Link href="tel:(415)272-0531" className="flex flex-col items-center justify-center w-full h-full text-themeSecondary hover:text-primary">
                                <MdPhone className="h-6 w-6" />
                            </Link>
                        </div>
                    </nav>
                </>
            )}
        </>
    )
}
"use client"

import React, { useState } from "react"
import Link from "next/link"
import Image from "next/image"

interface ServiceCardProps {
    title: string
    description: string
    image: string
    icon: React.ReactNode
    href: string
}

const ServiceCard: React.FC<ServiceCardProps> = ({ href, title, description, image }) => {
    const [isExpanded, setIsExpanded] = useState(false)

    const toggleExpand = (e: React.MouseEvent) => {
        e.preventDefault()
        e.stopPropagation()
        setIsExpanded(!isExpanded)
    }

    return (
        <Link href={href} className="block">
            <div className={`border border-gray-300 rounded-lg overflow-hidden shadow-md ${isExpanded ? 'h-auto' : 'h-96'} transition-all duration-300`}>
                {/* Image */}
                <div className="relative w-full h-48">
                    <Image src={image} alt={title} layout="fill" objectFit="cover"/>
                    {/* Icon overlay */}
                    {/*<div className="z-2 absolute right-4 -bottom-4 bg-themeSecondary p-2 rounded-sm">*/}
                    {/*    {icon}*/}
                    {/*</div>*/}
                </div>
                {/* Content */}
                <div className={`p-6 bg-gray-200 hover:invert ${isExpanded ? 'h-auto' : 'h-48'} flex flex-col relative transition-all duration-300`}>
                    <h3 className="text-xl font-bold mb-2 truncate" title={title}>
                        {title}
                    </h3>
                    <div className={`text-gray-600 ${isExpanded ? 'h-auto' : 'h-20 overflow-hidden'}`}>
                        <p>{description}</p>
                    </div>
                    <button
                        onClick={toggleExpand}
                        className="text-gray-500 hover:text-gray-700 mt-2 text-sm"
                    >
                        {isExpanded ? 'Read less' : 'Read more'}
                    </button>
                </div>
            </div>
        </Link>
    )
}

export default ServiceCard
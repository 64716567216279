import React from 'react';
import ServiceCard from '../templates/ServiceCard';
import { FaOilCan } from 'react-icons/fa';

const AirConServiceCard: React.FC = () => {
    return (
        <ServiceCard
            title="Air Conditioning"
            description="Beat the heat with our comprehensive air conditioning services for R1234yf and R134a systems. We provide thorough inspections, repairs, and recharges to keep you cool and comfortable."
            image="/imgs/masiAirCon.jpg"
            icon={<FaOilCan className="text-white text-xl" />}
            href="/services/airConditioning"
        />
    );
};

export default AirConServiceCard;
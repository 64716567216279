import React from "react";

const BookAppointmentButtonWhite: React.FC = () => {
  return (
    <a
      href={"https://myalp.io/t173zp"}
      className={"bg-white text-themePrimary py-2 px-4 rounded-sm hover:text-white hover:bg-themeSecondary transition-colors duration-300"}
    >
      Book Appointment
    </a>
  );
};

export default BookAppointmentButtonWhite;

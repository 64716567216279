"use client"

import React, { useState, useEffect, ReactNode } from "react"
import { ChevronLeft, ChevronRight } from "lucide-react"

interface FlexibleCarouselProps {
  children: ReactNode[]
}

export default function FlexibleCarousel({ children }: FlexibleCarouselProps) {
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isDesktop, setIsDesktop] = useState(true)

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth >= 768)
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])

  const visibleCards = isDesktop ? 3 : 2
  const maxIndex = Math.max(0, React.Children.count(children) - visibleCards)

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === maxIndex ? 0 : prevIndex + 1))
  }

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? maxIndex : prevIndex - 1))
  }

  const goToSlide = (index: number) => {
    setCurrentIndex(index)
  }

  return (
      <div className="w-full max-w-6xl mx-auto px-4">
        <div className="relative">
          <div className="overflow-hidden">
            <div
                className="flex transition-transform duration-300 ease-in-out"
                style={{
                  transform: `translateX(-${currentIndex * (100 / visibleCards)}%)`,
                }}
            >
              {React.Children.map(children, (child, index) => (
                  <div
                      key={index}
                      className="flex-shrink-0 w-1/2 md:w-1/3 px-2"
                  >
                    {child}
                  </div>
              ))}
            </div>
          </div>
          <button
              onClick={prevSlide}
              className="absolute top-1/2 -left-5 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              aria-label="Previous slide"
          >
            <ChevronLeft className="w-6 h-6" />
          </button>
          <button
              onClick={nextSlide}
              className="absolute top-1/2 -right-5 transform -translate-y-1/2 bg-white p-2 rounded-full shadow-md hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary"
              aria-label="Next slide"
          >
            <ChevronRight className="w-6 h-6" />
          </button>
        </div>
        <div className="flex justify-center mt-4 space-x-2">
          {React.Children.map(children, (_, index) => (
              <button
                  key={index}
                  onClick={() => goToSlide(index)}
                  className={`w-3 h-3 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary ${
                      currentIndex === index ? "bg-primary" : "bg-gray-300"
                  }`}
                  aria-label={`Go to slide ${index + 1}`}
              />
          ))}
        </div>
      </div>
  )
}
import React from "react";

const BookAppointmentButtonPurple: React.FC = () => {
  return (
    <a
      href={"https://myalp.io/t173zp"}
      className={"text-xl bg-themePrimary text-white py-3 px-8 rounded-sm hover:text-themePrimary hover:bg-white transition-colors duration-300 items-center justify-center"}
    >
      Book Appointment
    </a>
  );
};

export default BookAppointmentButtonPurple;

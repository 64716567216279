import React from 'react';
import ServiceCard from '../templates/ServiceCard';
import { Cog } from "lucide-react"

const BrakeServiceCard: React.FC = () => {
    return (
        <ServiceCard
            title="Brake Repair"
            description="Ensure your safety on the road with expert brake repairs at Masonic Smog & Repair. Our certified technicians perform precise inspections and repairs to keep your brakes in optimal condition."
            image="/imgs/brakejob.jpg"
            icon={<Cog className="text-white text-xl" />}
            href="/services/brakeRepair"
        />
    );
};

export default BrakeServiceCard;
import React from 'react';
import ServiceCard from '../templates/ServiceCard';
import { FaOilCan } from 'react-icons/fa';

const TransServiceCard: React.FC = () => {
    return (
        <ServiceCard
            title="Transmission Services"
            description="Ensure a smooth ride with our expert transmission services. We offer detailed diagnostics and repairs to maintain your vehicle's handling and stability."
            image="/imgs/bmwTrans.jpg"
            icon={<FaOilCan className="text-white text-xl" />}
            href="/services/trans"
        />
    );
};

export default TransServiceCard;